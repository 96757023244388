body {
  height: 100%; 
  overflow-x: auto;
}

.background-white {
  background-color: white;
}

.background-gray {
  background-color: #f5f5f5;
}

.gutter-narrow {
    margin-right: auto;
    margin-left: auto;

    width: 100%;
    max-width: 1146px;

    overflow: hidden;
}

@media (min-width: 1020px) {
  #main-navbar .container {
      width: 1020px;
  }
}

@media (max-width: 1020px) {
  .container {
    width: auto;
  }
}

.bb-navbar
{
  background-color: white;
  border: none;
  border-radius: 0px;
 margin-right: -15px;
  margin-left: -15px;
border-bottom: solid 1px 9B9B9B;;
}

.main-navbar-padding {
  padding-top: 196px;
}
@media (max-width: 560px) {
  .main-navbar-padding {
    padding-top: 74px;
  }
}

p {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

@media (min-width: 561px) {
   img.site-logo {
    max-width: 76px;
    max-height: 76px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}
@media (max-width: 560px) {
  img.site-logo {
    height: 30px; 
    margin-top: 5px;
    margin-bottom: 5px;
  }
  
  .navbar .container {
    padding-left: 0;
    padding-right: 0;
  }
  
  .site-title-block {
    height: 50px;
  }
  .site-header-block .media-left {
    display: none;
  }
  .site-header-block .media-right, .media > .pull-right {
    padding-left: 0;
  }
  .navbar-header.media {
    margin: 0;
  }
  
  .site-title {
    font-size: 19px;
  }
  .site-subtitle {
    font-size: 7px;
    margin-bottom: 0;
  }
  
  .navbar-toggle {
    border: none;
    border-radius: 0;
    margin-right: 0;
    height: 20px;
  }
  .navbar-default .navbar-toggle:hover, .navbar-default .navbar-toggle:focus {
    background-color: transparent;
  }
}

@media (max-width: 837px) {
  .nav > li > a {
    padding-left: 8px;
    padding-right: 8px;
  }
}

.dropdown-menu.dropdown-menu-panel {
  padding: 0 !important;
}
.dropdown-panel-header {
  background: #31b0d5;
  color: black;
  font-weight: bold;
}

.fixed-search-box form.search-form.navbar-form {
  padding: 0;
}

.search-panel .navbar-form,
.dropdown-menu-panel .navbar-form {
  border: none;
  margin: 0;
  padding: 0;
}

.dropdown-menu-panel th,
.dropdown-menu-panel td,
.dropdown-menu-panel td a {
  color: black;
  font-size: 12px;
}
.dropdown-menu-panel th {
  background-color: #f5f5f5;
}
.dropdown-menu-panel td table {
  width: 10em;
}
.dropdown-menu-panel table {
  margin-bottom: 0;
}
.dropdown-menu-panel a.more-editions {
  color: #31b0d5;
}

.navbar.navbar-fixed-top img.site-logo {
  height: 30px; 
  margin-top: 5px;
  margin-bottom: 5px;
}

.stuck img.site-logo {
  height: 30px; 
  margin-top: 5px;
  margin-bottom: 5px;
  /*padding-left:15px;*/
}

.site-title .left {
  color: #444443;
}
.site-title .right {
  color: #63B6E6;
}

.full-underline {
  clear: both;
  border-bottom: 1px solid #5D5D5D;
  margin-top: 10px;
  margin-bottom: 15px;
}

.related-topics-btn {
  padding-top: 3px;
  padding-bottom: 3px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.large-banner-panel {
  margin-bottom: 15px;
}

.large-banner-panel img {
  max-width: 100%;
  border: none;
}

.brought-by {
  color: #A3A3A3;
  width: 8em;
  font-size: 16px;
  margin: auto;
}
@media (max-width: 560px) {
  .hidden-xs1 {
    display: none !important;
  }
}
@media screen and (min-width: 561px) and (max-width: 837px)
{
  .brought-by-sm {
    p{
    color: #A3A3A3;
    width: 8em;
    font-size: 8pt;
    font-family: Helvetica, Sans-Serif;
    margin: auto;
    right:22px;
    left:auto;
    }
    a{
      display: none !important;
    }
    p.brought-by-hidden-mx {
      font-size: 9pt;
    }
  }
}

.navbar {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 18px;
  color: 9B9B9B;
}

.bb-join-block {
  right: 0px;
  margin-bottom: 15px;
}

.parallax {

    -webkit-box-sizing: content-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: content-box; /* Firefox, other Gecko */
    box-sizing: content-box; /* Opera/IE 8+ */

    height: 225px;
    overflow: hidden;
    position: relative;
    border-bottom: solid 5px #64b6e6;
}

/*
.parallax:before {
    box-shadow: 0 15px 15px -15px inset; 
    content: " ";
    height: 15px;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;

    z-index: 2;
}
.parallax:after {
    box-shadow: 0 -15px 15px -15px inset;
    content: " ";
    height: 15px;
    position: absolute;
    bottom: 0;
    width: 100%;
    
    z-index: 2;
}
*/

.parallax img {
/*    position:absolute;*/
    width: 100%;
/*
    filter: grayscale(5%);
    -webkit-filter: grayscale(5%);
    -moz-filter: grayscale(5%);
    -o-filter: grayscale(5%);
    -ms-filter: grayscale(5%);*/
}

.dimmed {
    -webkit-box-sizing: content-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: content-box; /* Firefox, other Gecko */
    box-sizing: content-box; /* Opera/IE 8+ */

    overflow: hidden;
    position: relative;
    border-bottom: solid 5px #64b6e6;
}

.dimmed:before {
    position: absolute; 
    z-index: 2;
    display: block; 
    content: "";
    top: 0; right: 0; bottom: 0; left: 0;  
    background: hsla(0,0%,0%,0.05);
}

/****************************************************************************************************************************/

input,
button,
select,
textarea {
  color: black;
}




.remove-topic {
  display: inline-block;
  margin-right: 1em;
}

.remove-topic img, 
.remove-topic span {
  display: inline-block;
  vertical-align: middle;
}

.remove-topic a:hover, 
.remove-topic a:focus {
  text-decoration: none;
}

.remove-topic span {
  color: #777777;
  font-weight: bold;
  font-size: 20px;
}

.inputbox { 
  padding: 0 5px 0 0;
  overflow: hidden; 
}
.inputbox input { 
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; 
}

.btn a {
  text-decoration: none;
}

form.user-registration-form.bb-article-content {
  display: block;
  width: 100%;
  margin-top: 0;
  color: #ffffff;
  background-color: #5bc0de;
  border-color: #46b8da;
}

form.user-registration-form.bb-article-content .validation,
form.user-registration-form.bb-article-content .validation p {
  color: #a00000;
  margin: 0;
  font-weight: bold;
  font-size:14px; 
  white-space:normal;
}


.frontpage-signup-block {
  position: relative;
  
  margin-top: 15px;
  margin-bottom: 30px;
  
  padding: 52px 40px 32px 40px;
  
  /* This is lazy loaded now instead. */
  /*background-image: url('/images/signup-background.jpg');*/
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  
  font-family: Helvetica, Arial, sans-serif;
  color: #ffffff; 
}

.frontpage-signup-block h3 {
  font-weight: bold;
  font-size: 35px;
  margin-top:0;
  margin-bottom:15px;
}

.frontpage-signup-block p {
  font-size: 20px;
  margin-top:0;
  margin-bottom:40px; 
}

.frontpage-signup-block .btn {
  min-width: 160px;
  font-size: 22px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-right: 13px;
  margin-bottom: 10px;
}

.frontpage-signup-block .btn.btn-outline-white {
  padding-top: 9px;
  padding-bottom: 9px;
}

.frontpage-signup-block-gradient {
  position: absolute;
  display: block;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(to left, rgba(255,255,255,0.5) 0%, rgba(91,192,222,0.95) 50%, rgba(91,192,222,1) 100%);
}

.btn-outline-white {
  color: white;
  background-color: transparent;
  background-image: none;
  border-color: white;
  border-radius: 0;
  border: solid 2px white;
}

.btn-outline-white:hover {
  color: #67b7e4 !important;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-white:focus, .btn-outline-white.focus {
  color: white;
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

.btn-outline-white.disabled, .btn-outline-white:disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-white:not(:disabled):not(.disabled):active, .btn-outline-white:not(:disabled):not(.disabled).active,
.show > .btn-outline-white.dropdown-toggle {
  color: #67b7e4;;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-white:not(:disabled):not(.disabled):active:focus, .btn-outline-white:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-white.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(248, 249, 250, 0.5);
}

@media (max-width: 837px) {
  .frontpage-signup-block {
    padding: 42px 30px 24px 30px;
  }
  .frontpage-signup-block h3 {
    font-size: 30px;
  }
  .frontpage-signup-block p {
    font-size: 16px;
    margin-bottom:22px; 
  }
  .frontpage-signup-block .btn {
    min-width: 130px;
    font-size: 16px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-right: 1em;
  }
}

@media (max-width: 560px) {
  .frontpage-signup-block {
    padding: 37px 18px 23px 18px;
  }
  .frontpage-signup-block h3 {
    font-size: 30px;
  }
  .frontpage-signup-block p {
    font-size: 16px;
    margin-bottom:33px; 
  }
  .frontpage-signup-block .btn {
    min-width: 150px;
    font-size: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-right: 1em;
  }
}


.navbar-nav > li > .dropdown-menu {
  padding: 5px;
  border-radius: 0;
}

@media (min-width: 838px) {
  .navbar-default .navbar-nav > li > a.signInButton {
    color: #2698d8;
  }
}

.advertisement-tag {
  color: #67b7e4;
}

.ad-card.dimmed {
  background-color: black;
}
.ad-card a {
  position:relative;
  z-index: 1000;
  
}
.ad-card img {
  width: 100%;
  height: 100%;
  opacity: 0.95;
}

.validation,
.modal-dialog .validation,
.messages {
  font-size: 20px;
  color: red;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 5px;
}

@media (max-width: 837px) {
  .bb-join-block {
    visibility: hidden;
    height: 0;
    margin-bottom: 0 !important;
  }
}

.topics-menu {
  float: none;
  margin-right: -15px !important;
  margin-left: -15px !important;
}

.topics-menu .dropdown-menu a.dropdown-toggle {
  line-height: 24px;
  font-size: 15px;
  color: #606161;
}

a.more-topics-link {
  color: #1ea7c6 !important;
}

.topics-menu .dropdown-menu a.more-topics-link {
  line-height: 24px;
  font-size: 15px;
}

.topics-menu .dropdown-menu .dropdown-submenu a.mobile-menu-item,
.mobile-menu .dropdown-menu .dropdown-submenu a.mobile-menu-item {
  line-height: 19px;
  font-size: 12px;
}

/*
@media (min-width: 561px) {
  .topics-menu.navbar-collapse.collapse {
    max-height: 50px !important;
    overflow: hidden !important;
  }
}
*/

/* Prevent word-wrap  */
/*
.navbar .topics-menu.nav,
.navbar .topics-menu.nav li {
  white-space: nowrap; 
}
*/
.transparent-button {
  background: transparent;
  border: none;
}
.transparent-button:focus {
  outline: none;
}
.inputbox button {
  vertical-align: middle;
}
.navbar-form .input-group {
  display: table; 
}
.dropdown-menu .navbar-form .input-group {
  min-width: 20em;
}
.navbar-form .input-group input,
.navbar-form .input-group button
{
  line-height: 20px;
}
.navbar-form .input-group input {
  border: none;
}
.navbar-form .input-group-btn {
  width: 1% !important;
}
.btn.flat.btn-warning {
  border: solid 1px #f0ad4e;
}

.fixed-search-box {
  /*margin-right: -15px !important;*/
  padding-right: 0;
  max-width: 14em;
}
.fixed-search-box input {
  max-width: 10em;
}

.popover {
  padding: 0;
  border: none;
  border-radius: 0;
}
.popover.top > .arrow:after {
  border-top-color: #67b7e4;
}
.popover.bottom > .arrow:after {
  border-bottom-color: #67b7e4;
}
.popover-content {
  padding: 0;
}
.popover-content a.topic {
  padding-left: 5px;
  padding-right: 5px;
  margin: 0 0 1px 0;
}
.popover-content .keyword {
  padding-left: 0;
  padding-right: 0;
}
.popover-content .keyword:last-child {
  margin-bottom: 0;
}


.dropdown-submenu {
    position:relative;
}
.dropdown-submenu>.dropdown-menu {
    top:0;
    left:100%;
    margin-top:-6px;
    margin-left:-1px;
    -webkit-border-radius:0 6px 6px 6px;
    -moz-border-radius:0 6px 6px 6px;
    border-radius:0 6px 6px 6px;
}
/*
.dropdown-submenu>a:after {
    display:block;
    content:" ";
    float:right;
    width:0;
    height:0;
    border-color:transparent;
    border-style:solid;
    border-width:5px 0 5px 5px;
    border-left-color:#cccccc;
    margin-top:5px;
    margin-right:-10px;
}
.dropdown-submenu:hover>a:after {
    border-left-color:#ffffff;
}
*/
.dropdown-submenu.pull-left {
    float:none;
}
.dropdown-submenu.pull-left>.dropdown-menu {
    left:-100%;
    margin-left:10px;
    -webkit-border-radius:6px 0 6px 6px;
    -moz-border-radius:6px 0 6px 6px;
    border-radius:6px 0 6px 6px;
}


body.mobile-menu-opened {
  position: fixed; 
  top: 0px; 
  right: 0px; 
  left: 0px;
  overflow: auto;
}

@media (min-width: 561px) {
  .navbar-collapse.collapse.mobile-menu {
    display: none !important;
  }
}

.mobile-menu {
  position: fixed;
  top: 52px;
  left: 0;
  
  width: 100% !important;
  height: 100% !important;
  
  margin: 0 !important;
  padding: 0 !important;
  z-index: 100000;
  
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  
  background: white;
  border: none;
}

.mobile-menu .nav {
  position: absolute;
  top:0;
  min-height: 101% !important;
  -webkit-overflow-scrolling: touch;
  z-index: 100000;
  
  width: 100%;
  margin: 0 !important;
  padding: 0 0 54px 0 !important;
  
  background: white;
}

.mobile-menu a {
  width: 100%;
}
    
a.mobile-menu-item {
  display: inline-block;
  background-color: #5bc0de;
  color: white !important;
  margin: 1px 0 1px 0; 
  /*padding: 4px 4px 4px 40px !important;*/
}

a.mobile-menu-item:focus,
a.mobile-menu-item:hover {
  background-color: #2698d8 !important;
  color: white !important;
}

.btn-personal-keyword,
.btn-personal-keyword:hover,
.btn-personal-keyword:focus,
.btn-personal-keyword:active,
.btn-personal-keyword:active:hover 
 {
  background-color: transparent !important;
  outline: none !important;
  margin: 0 0 0 -10px;
}

#main-navbar.on-top {
  z-index: 1048;
}

.dropdown-menu-blue {
  background-color: #5bc0de;
}

.dropdown-submenu-blue {
  background-color: #5bc0de;
}
.dropdown-submenu-blue .menu-item {
  border-bottom: solid 1px white;
}

.more-topics-link {
  display: block;
}

.mobile-menu .navbar-nav {
  margin: 0;
  //border-bottom: solid 5px #64b6e6;
}

.mobile-menu .navbar-nav > li,
.mobile-menu .navbar-nav > .menu-item.open > a,
.mobile-menu .navbar-nav > .menu-item.open > .dropdown-menu > .menu-item > a {
  border-bottom: solid 1px #9B9B9B;
}

.mobile-menu .modal-header {
    padding: 10px 5px 10px 5px;
    //box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.14);
    font-size: 18px;
}

.navbar-default .mobile-menu .navbar-nav > li.modal-header > a {
  color: #67b7e4;
}

.mobile-menu .modal-header .user-icon {
  display: inline-block;
  color: white;
  background-color: #5bc0de;
  text-align: center;
  line-height: 28px;
  width: 28px;
  height: 28px;
}

.mobile-menu .modal-header .user-name {
  margin-left: 5px;
  color: black;
}

/*
.mobile-menu .navbar-nav > .open > a, 
.mobile-menu .navbar-nav > .open > a:hover, 
.mobile-menu .navbar-nav > .open > a:focus {
  color: #67b7e4;
  background-color: transparent;
}
.mobile-menu .navbar-nav > li > a:hover, 
.mobile-menu .navbar-nav > li > a:focus {
  color: #67b7e4;
}
*/

.mobile-menu .navbar-nav > .open > a, 
.mobile-menu .navbar-nav > .open > a:hover, 
.mobile-menu .navbar-nav > .open > a:focus {
  background-color: #e7e7e7;
}

/*
.mobile-menu .navbar-nav .open .dropdown-menu > li > a {
  color: #67b7e4;
  background-color: white;
}
*/

.dropdown-submenu > a:after,
.mobile-menu .navbar-nav > .menu-item > a:after,
.mobile-menu .dropdown-submenu > a:after {
    display: block;
    content: " ";
    float: right;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 5px 5px 5px 5px;
    border-top-color: #cccccc;
    margin-top: 7px;
}

.dropdown-submenu.open>a:after {
    display: block;
    content: " ";
    float: right;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 5px 5px 5px 5px;
    border-bottom-color: #ccc;
    margin-top: 5px;    
}

.dropdown-submenu > a:after {
    margin-top: 10px;
    margin-right: -15px;
}

.mobile-menu .navbar-nav > .menu-item > a:after,
.mobile-menu .dropdown-submenu > a:after {
    margin-top: 7px;
}

@media (min-width: 561px) {
  .topics-menu.collapse.navbar-collapse.nav.navbar-nav .open .dropdown-menu {
    max-width: 430px;
  }
}
.mobile-menu .navbar-nav .open .dropdown-menu {
  padding: 0;
}

.topics-menu .dropdown-menu .dropdown-submenu a.mobile-menu-item,
.topics-menu .dropdown-menu a.dropdown-toggle,
.mobile-menu .dropdown-menu .dropdown-submenu a.mobile-menu-item {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/*
.mobile-menu-button.menu-opened button.mobile-menu-open {
  display: none;
  visibility: hidden;
}
.mobile-menu-button:not(.menu-opened) button.mobile-menu-close {
  display: none;
  visibility: hidden;
}
button.mobile-menu-close i {
  font-size: 26px;
  width: 22px;
  height: 38px;
  line-height: 22px;
}
*/

.submenu-call-to-action {
  background: #e5e5e5;
  padding-top: 11px;
  padding-bottom: 17px;
}

.submenu-call-to-action div.call-to-action {
  font-size: 15px;
  font-weight: bold; 
  color: #606261;
  margin-bottom:5px;
}

.submenu-call-to-action a.btn {
  display: inline;
  font-size: 12px;
  line-height: 18px;
  color: #ffffff !important;
  min-width: 120px;
  margin-left: auto;
  margin-right: auto;
}

.submenu-call-to-action a.btn:hover {
  color: #ffffff !important;
  background-color: #d58512 !important;
  border-color: #f0ad4e !important;
}

.c-hamburger {
  position: relative;
  overflow: hidden;
  margin: 0;
  padding: 0;
  width: 38px;
  height: 38px;
  line-height: 22px;
  font-size: 26px;
  margin-top: 2px;
  margin-bottom: 8px;
  font-size: 0;
  text-indent: -9999px;
  appearance: none;
  box-shadow: none;
  border-radius: none;
  border: none;
  cursor: pointer;
  transition: background 0.3s;
}

.c-hamburger:focus {
  outline: none;
}

.c-hamburger span {
  display: block;
  position: absolute;
  top: 17px;
  left: 9px;
  right: 9px;
  height: 4px;
  background: black;
}

.c-hamburger span::before,
.c-hamburger span::after {
  position: absolute;
  display: block;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: black;
  content: "";
}

.c-hamburger span::before {
  top: -7px;
}

.c-hamburger span::after {
  bottom: -7px;
}

.c-hamburger--htx {
  background-color: white;
}

.c-hamburger--htx span {
  transition: background 0s 0.3s;
}

.c-hamburger--htx span::before,
.c-hamburger--htx span::after {
  transition-duration: 0.3s, 0.3s;
  transition-delay: 0.3s, 0s;
}

.c-hamburger--htx span::before {
  transition-property: top, transform;
}

.c-hamburger--htx span::after {
  transition-property: bottom, transform;
}

/* active state, i.e. menu open */
.c-hamburger--htx.is-active {
  //background-color: #cb0032;
}

.c-hamburger--htx.is-active span {
  background: none;
}

.c-hamburger--htx.is-active span::before {
  top: 0;
  transform: rotate(45deg);
}

.c-hamburger--htx.is-active span::after {
  bottom: 0;
  transform: rotate(-45deg);
}

.c-hamburger--htx.is-active span::before,
.c-hamburger--htx.is-active span::after {
  transition-delay: 0s, 0.3s;
}

/* active state in dropdown menu */
.dropdown.open .c-hamburger--htx {
  //background-color: #cb0032;
}
.dropdown.open .c-hamburger--htx span {
  background: none;
}
.dropdown.open .c-hamburger--htx span::before {
  top: 0;
  transform: rotate(45deg);
}
.dropdown.open .c-hamburger--htx span::after {
  bottom: 0;
  transform: rotate(-45deg);
}
.dropdown.open .c-hamburger--htx span::before,
.dropdown.open .c-hamburger--htx span::after {
  transition-delay: 0s, 0.3s;
}



.st-custom {
  display: inline-block;
  padding: 8px 4px 8px 4px;
  margin: 0 4px 4px 4px;
  background: #bababa;
}
.st-custom:hover,
.st-custom:focus {
  opacity: .75;
  cursor: pointer;
}
.st-custom i {
  color: white;
  font-size: 16px;
  font-weight: bold;
  width: 1.5em;
}

.plus-container {
  position: absolute; 
  bottom: 20px;
}



  
  .bb-article-block .st-container {
    display: block;
  }
  .bb-article-block .st-custom {
    width: 33%;
    padding: 2px 4px 2px 4px;
    margin-left: 0;
    margin-right: 0;
  }
  .bb-article-block .st_facebook_custom {
    background-color: #3b579d;
  }
  .bb-article-block .st_linkedin_custom {
    background-color: #057bb6;
  }
  .bb-article-block .st_twitter_custom {
    background-color: #08aced;
  }


.bb-social-links-block {
  margin-top: 1em;
  margin-bottom: 1em;
}
.bb-social-links-block .st-custom {
  display: inline-block;
  padding: 8px 12px 8px 12px;
  margin: 0 4px 4px 4px;
  background: #bababa;
  color: white;
  text-decoration: none;
}
.bb-social-links-block .st-custom:hover {
  opacity: .75;
  cursor: pointer;
  color: white;
  text-decoration: none;
}
.bb-social-links-block .st-custom i {
  font-size: 16px;
  font-weight: bold;
  width: 1.5em;
}
.bb-social-links-block .st_facebook_custom {
  background-color: #3b579d;
}
.bb-social-links-block .st_linkedin_custom {
  background-color: #057bb6;
}
.bb-social-links-block .st_twitter_custom {
  background-color: #08aced;
}


  
@media (min-width: 561px) {
  .bb-article-detail-block .st-container,
  .dropdown-menu .st-container {
    position: absolute; 
    bottom: 20px;
  }
}

@media (max-width: 560px) {
  .bb-article-detail-block .st-container,
  .dropdown-menu .st-container {
    display: block;
  }
  .bb-article-detail-block .st-custom,
  .dropdown-menu .st-custom {
    width: 25%;
    padding: 2px 4px 2px 4px;
    margin-left: 0;
    margin-right: 0;
  }
  .bb-article-detail-block .st_facebook_custom,
  .dropdown-menu .st_facebook_custom {
    background-color: #3b579d;
  }
  .bb-article-detail-block .st_linkedin_custom,
  .dropdown-menu .st_linkedin_custom {
    background-color: #057bb6;
  }
  .bb-article-detail-block .st_twitter_custom,
  .dropdown-menu .st_twitter_custom {
    background-color: #08aced;
  }
}



.bb-article-block {
    -webkit-perspective: 400px;
       -moz-perspective: 400px;
        -ms-perspective: 400px;
         -o-perspective: 400px;
            perspective: 400px;
  
    -webkit-perspective-origin: 50% 50%;
       -moz-perspective-origin: 50% 50%;
        -ms-perspective-origin: 50% 50%;
         -o-perspective-origin: 50% 50%;
            perspective-origin: 50% 50%;
  }
  .bb-article-block.compact .hidden-expanded-xs {
    opacity:1;
    max-height: 999px; 
  }
  .bb-article-block.expanded .hidden-expanded-xs {
    opacity:0;
    max-height: 0;
    overflow: hidden;
    transition: all .3s ease .15s;
  }
  .bb-article-block.expanded .hidden-compact-xs {
    opacity:1;
    max-height: 999px;
    
    -webkit-transition: all 600ms ease,
              opacity 300ms ease;
       -moz-transition: all 600ms ease;
        -ms-transition: all 600ms ease,
                opacity 300ms ease;
         -o-transition: all 600ms ease,
                opacity 300ms ease;
            transition: all 600ms ease,
                  opacity 300ms ease;

    -webkit-transform-origin: 0% 0%;
       -moz-transform-origin: 0% 0%;
        -ms-transform-origin: 0% 0%;
         -o-transform-origin: 0% 0%;
            transform-origin: 0% 0%;
  }
  .bb-article-block.compact .hidden-compact-xs {
    opacity:0;
    max-height: 0;
    overflow: hidden;
    transition: all .3s ease .15s;

    -webkit-transform-origin: 0% 100%;
       -moz-transform-origin: 0% 100%;
        -ms-transform-origin: 0% 100%;
         -o-transform-origin: 0% 100%;
            transform-origin: 0% 100%;

    -webkit-transform: rotateX( 80deg );
       -moz-transform: rotateX( 80deg );
        -ms-transform: rotateX( 80deg );
         -o-transform: rotateX( 80deg );
            transform: rotateX( 80deg );
  }
  


.coming-soon-page h1 {
  font-size: 80px;
  font-weight: bold;
  border-bottom: solid 3px;
  padding-bottom: 0.1em;
}

@media (max-width: 837px) {
  .coming-soon-page h1 {
    font-size: 60px;
  }
}

@media (max-width: 560px) {
  .coming-soon-page h1 {
    font-size: 40px;
  }
}

.coming-soon-page form {
  margin-top: 2em;
  margin-bottom: 2em;
}

.coming-soon-page form input {
  margin-bottom: 10px;
}

.coming-soon-page .coming-soon-footer i {
  font-size: 5px;
  line-height: 20px;
  vertical-align: middle;
}

.coming-soon-page .site-title {
  margin-top: 0;
}
@media (max-width: 560px) {
  .coming-soon-page .site-title {
      font-size: 28px;
  }
}

.coming-soon-page .error li {
  color: red;
  list-style-type: none;
}

.coming-soon-page .thanks-block {
  margin-bottom: 2em;
}


.grid-sizer {
  box-sizing: border-box;
  padding: 0 15px 0 15px;
}

.bb-article-block.post.col-xs-12 {
  width: 99.9% !important;
}
.bb-article-block.post.col-xs-6 {
  width: 49.9% !important;
}
.bb-article-block.post.col-xs-4 {
  width: 33.3% !important;
}
.bb-article-block.post.col-xs-3 {
  width: 24.9% !important;
}
@media (min-width: 561px) {
  .bb-article-block.post.col-sm-12 {
    width: 99.9% !important;
  }
  
  .bb-article-block.post.col-sm-6 {
    width: 49.9% !important;
  }
  
  .bb-article-block.post.col-sm-4 {
    width: 33.3% !important;
  }
  
  .bb-article-block.post.col-sm-3 {
    width: 24.9% !important;
  }
   
}
@media (min-width: 838px) {
  .bb-article-block.post.col-md-12 {
    width: 99.9% !important;
  }
  
  .bb-article-block.post.col-md-6 {
    width: 49.9% !important;
  }
  
  .bb-article-block.post.col-md-4 {
    width: 33.3% !important;
  }
  
  .bb-article-block.post.col-md-3 {
    width: 24.9% !important;
  }
}
@media (min-width: 1020px) {
  .bb-article-block.post.col-lg-12 {
    width: 99.9% !important;
  }
  
  .bb-article-block.post.col-lg-6 {
    width: 49.9% !important;
  }
  
  .bb-article-block.post.col-lg-4 {
    width: 33.3% !important;
  }
  
  .bb-article-block.post.col-lg-3 {
    width: 24.9% !important;
  }
}

.side-section {
  margin-top: 0;
  border: solid 1px #67b7e4;
}

.bb-join-block .side-section:not(:first-child) {
  margin-top: 15px;
}

.side-section h3 {
  font-size: 18px;
  padding: 5px;
  margin-top: 0;
  margin-bottom: 0;
  background: #67b7e4;
  color: #3c3c3c;
  text-align: center;
}

.side-section p {
  margin: 1em;
}

.side-section a {
  color: black;
  text-decoration: none;
}

.side-section a:hover {
  color: #67b7e4;
}

.side-section a.more {
  color: #5bc0de;
  text-decoration: none;
}

.side-section  ul {
  padding: 14px;  
  list-style-type: none;
  margin-bottom: 0;
}

.nav .dropdown-menu .dropdown-submenu > .dropdown-menu {
    position: static;
    float: none;
    width: auto;
    -webkit-box-shadow: none;
    box-shadow: none;
    margin: 0;
    padding:0;
    background-color: transparent;
    border: 0;
}
 
.nav .dropdown-menu .dropdown-submenu.open > a:focus:not(:hover) {
    background-color: #ffffff;
}

.nav .dropdown-menu > li > a.more-topics-link {
  color: #67b7e4;
}

.nav .dropdown-menu > li > a {
  padding: 3px 8px 3px 8px;
}

.nav .dropdown-menu > li > a.dropdown-toggle,
.nav .dropdown-menu > li > div > a.dropdown-toggle {
  padding-right: 20px;
}

@media (max-width: 560px) {
  .nav > li > a {
    padding-right: 20px;
  }
  
  .mobile-menu .navbar-nav > .menu-item > a:after{
    margin-right: -15px;
  }
  
  .navbar-nav .open .dropdown-menu > li > a, 
  .navbar-nav .open .dropdown-menu .dropdown-header {
    padding: 5px 8px 5px 8px;
  }
  
  .navbar-nav .open .dropdown-menu > li > a.dropdown-toggle, 
  .navbar-nav .open .dropdown-menu > li > div > a.dropdown-toggle {
    padding-right: 20px;
  }
}


.ellipsis {
  overflow: hidden;
  height: 38px;
  line-height: 19px;
}

.ellipsis:before {
  content:"";
  float: left;
  width: 5px; 
  height: 38px; 
}

.ellipsis > *:first-child {
  float: right;
  width: 100%;
  margin-left: -5px; 
}

.ellipsis:after {
  content: "\02026";
  color: white;  

  box-sizing: content-box;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;

  float: right; position: relative;
  top: -19px; left: 100%; 
  width: 3em; margin-left: -3em;
  padding-right: 5px;
  
  text-align: right;
  
  background-size: 100% 100%;
  /* 512x1 image, gradient for IE9. Transparent at 0% -> white at 50% -> white at 100%.*/
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAgAAAAABCAMAAACfZeZEAAAABGdBTUEAALGPC/xhBQAAAwBQTFRF////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////AAAA////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////wDWRdwAAAP90Uk5TgsRjMZXhS30YrvDUP3Emow1YibnM9+ggOZxrBtpRRo94gxItwLOoX/vsHdA2yGgL8+TdKUK8VFufmHSGgAQWJNc9tk+rb5KMCA8aM0iwpWV6dwP9+fXuFerm3yMs0jDOysY8wr5FTldeoWKabgEJ8RATG+IeIdsn2NUqLjQ3OgBDumC3SbRMsVKsValZplydZpZpbJOQco2KdYeEe36BDAL8/vgHBfr2CvTyDu8R7esU6RcZ5ecc4+Af3iLcJSjZ1ivT0S/PMs3LNck4x8U7wz7Bv0G9RLtHuEq1TbJQr1OtVqqnWqRdoqBhnmSbZ5mXapRtcJGOc4t2eYiFfH9AS7qYlgAAARlJREFUKM9jqK9fEGS7VNrDI2+F/nyB1Z4Fa5UKN4TbbeLY7FW0Tatkp3jp7mj7vXzl+4yrDsYoVx+JYz7mXXNSp/a0RN25JMcLPP8umzRcTZW77tNyk63tdprzXdmO+2ZdD9MFe56Y9z3LUG96mcX02n/CW71JH6Qmf8px/cw77ZvVzB+BCj8D5vxhn/vXZh6D4uzf1rN+Cc347j79q/zUL25TPrJMfG/5LvuNZP8rixeZz/mf+vU+Vut+5NL5gPOeb/sd1dZbTs03hBuvmV5JuaRyMfk849nEM7qnEk6IHI8/qn049hB35QGHiv0yZXuMdkXtYC3ebrglcqvYxoj1muvC1nDlrzJYGbpcdHHIMo2FwYv+j3QAAOBSfkZYITwUAAAAAElFTkSuQmCC);
  
  background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), to(#5bc0de), color-stop(50%, #5bc0de));
  background: -moz-linear-gradient(to right, rgba(255, 255, 255, 0), #5bc0de 50%, #5bc0de);     
  background: -o-linear-gradient(to right, rgba(255, 255, 255, 0), #5bc0de 50%, #5bc0de);
  background: -ms-linear-gradient(to right, rgba(255, 255, 255, 0), #5bc0de 50%, #5bc0de);
  background: linear-gradient(to right, rgba(255, 255, 255, 0), #5bc0de 50%, #5bc0de);
}

a:hover .ellipsis:after,
a:focus .ellipsis:after {
  /* 512x1 image, gradient for IE9. Transparent at 0% -> white at 50% -> white at 100%.*/
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAgAAAAABCAMAAACfZeZEAAAABGdBTUEAALGPC/xhBQAAAwBQTFRF////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////AAAA////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////wDWRdwAAAP90Uk5TgsRjMZXhS30YrvDUP3Emow1YibnM9+ggOZxrBtpRRo94gxItwLOoX/vsHdA2yGgL8+TdKUK8VFufmHSGgAQWJNc9tk+rb5KMCA8aM0iwpWV6dwP9+fXuFerm3yMs0jDOysY8wr5FTldeoWKabgEJ8RATG+IeIdsn2NUqLjQ3OgBDumC3SbRMsVKsValZplydZpZpbJOQco2KdYeEe36BDAL8/vgHBfr2CvTyDu8R7esU6RcZ5ecc4+Af3iLcJSjZ1ivT0S/PMs3LNck4x8U7wz7Bv0G9RLtHuEq1TbJQr1OtVqqnWqRdoqBhnmSbZ5mXapRtcJGOc4t2eYiFfH9AS7qYlgAAARlJREFUKM9jqK9fEGS7VNrDI2+F/nyB1Z4Fa5UKN4TbbeLY7FW0Tatkp3jp7mj7vXzl+4yrDsYoVx+JYz7mXXNSp/a0RN25JMcLPP8umzRcTZW77tNyk63tdprzXdmO+2ZdD9MFe56Y9z3LUG96mcX02n/CW71JH6Qmf8px/cw77ZvVzB+BCj8D5vxhn/vXZh6D4uzf1rN+Cc347j79q/zUL25TPrJMfG/5LvuNZP8rixeZz/mf+vU+Vut+5NL5gPOeb/sd1dZbTs03hBuvmV5JuaRyMfk849nEM7qnEk6IHI8/qn049hB35QGHiv0yZXuMdkXtYC3ebrglcqvYxoj1muvC1nDlrzJYGbpcdHHIMo2FwYv+j3QAAOBSfkZYITwUAAAAAElFTkSuQmCC);
  
  background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, 0)), to(#2698d8), color-stop(50%, #2698d8));
  background: -moz-linear-gradient(to right, rgba(255, 255, 255, 0), #2698d8 50%, #2698d8);     
  background: -o-linear-gradient(to right, rgba(255, 255, 255, 0), #2698d8 50%, #2698d8);
  background: -ms-linear-gradient(to right, rgba(255, 255, 255, 0), #2698d8 50%, #2698d8);
  background: linear-gradient(to right, rgba(255, 255, 255, 0), #2698d8 50%, #2698d8);
}

.topics-menu .dropdown-menu .dropdown-submenu a.mobile-menu-item.two-lines, 
.topics-menu .dropdown-menu a.dropdown-toggle.two-lines, 
.mobile-menu .dropdown-menu .dropdown-submenu a.mobile-menu-item.two-lines {
  white-space: normal;
}

.topics-menu .dropdown-menu .dropdown-submenu li.menu-item.ellipsis a.mobile-menu-item, 
.topics-menu .dropdown-menu li.menu-item.ellipsis a.dropdown-toggle, 
.mobile-menu .dropdown-menu .dropdown-submenu li.menu-item.ellipsis a.mobile-menu-item {
  white-space: normal;
  height: 38px;
}
  
@media (max-width: 200px) {
  .bottom-line-min {
    font-size: 7pt;
  }
  .bottom-line-min span {
    font-size: 7pt;
  }
}

@media screen and (min-width: 201px) and (max-width: 300px) { 
.bottom-line-min {
    font-size: 8pt;
  }
  .bottom-line-min span {
    font-size: 8pt;
  }
}

@media screen and (min-width: 301px) and (max-width: 400px) {
  .bottom-line-min {
    font-size: 9pt;
  }
  .bottom-line-min span {
    font-size: 9pt;
  }
}

@media screen and (min-width: 401px) and (max-width: 500px) {
  .bottom-line-min {
    font-size: 10pt;
  }
  .bottom-line-min span {
    font-size: 10pt;
  }
}

@media screen and (min-width: 501px) and (max-width: 560px) {
  .bottom-line-min {
    color: #A3A3A3;
    font-family: Helvetica;
    font-weight: Regular;
    font-size: 12pt;
  }
  .bottom-line-min span {
    color: #535352;
    font-family: Helvetica;
    font-weight: Regular;
    width: 8em;
    font-size: 12pt;
  }
}

@media screen and (max-width: 996px) {
  .hidden-fixed-top-mdxs2 {
    display: none !important;
  }
}
@media screen and (max-width: 589px) {
  .hidden-fixed-top-mdxs3 {
    display: none !important;
  }
}
@media screen and (max-width: 609px) {
  .hidden-fixed-top-mdxs1 {
    display: none !important;
  }
}

@media screen and (min-width: 561px) and (max-width: 837px) {
  p.brought-by-hidden-mx {
    color: #535352;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    max-width: 180px;
    font-size: 14pt;
    margin: auto;
  }
  .bottom-line-min {
    display: none !important;
  }
}
  
@media (min-width: 838px) {
  p.brought-by-hidden-mx {
    display: none !important;
  }
  .bottom-line-min {
    display: none !important;
  }
}
  
.bb-navbar-white {
  background-color: white;
  border: none;
  border-radius: 0px;
}



@media screen and (min-width: 561px) and (max-width: 746px) {
 .linkedin-min {
   margin-left:-5.2vw;
 }
 .twitter-min {
   margin-right: -1.48vw;
 }
}
  
.fallback-font {
  font-family: Arial, sans-serif;
}

ul.dotsbetween {
  list-style: none;
  display: flex;
  padding-left: 5px;
  padding-right: 5px;
  flex-flow: row wrap;
  align-items: stretch; /* Default */
  justify-content: space-between;
  width: 100%; 
  overflow: hidden;
  background-color: #d4d4d4;
  background-color: #eff0f0;
  height:27px;
  line-height: 27px;
}
ul.dotsbetween li a {
  white-space:nowrap;
  text-align: center;
  color:#7f8080;
  font-family:'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 17px;
}
ul.dotsbetween li {
  flex: 1 1 auto;
	color:#7f8080;
  font-family:'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 17px;
  margin-left: 5px;
  margin-right: 5px;
  text-align: center;
}

  
ul.dotsbetween li {
  position: relative;
}
    
ul.dotsbetween li + li::before {
  content: "\2022";
  position: absolute;
  left: -9px;
  margin-bottom:-1px;
  margin-top:1px;
  font-size: 17px;
}
ul.dotsbetween li a:not(:hover) {
    text-decoration:none;
}

a.mobile-menu-item.see-all {
  background-color: #0d85a8;
}

div#recaptchaV2JoinNow .rc-anchor-normal-footer {
  display:none
}
